<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row class="match-height" dense>
          <v-col cols="3" class="pb-0">
            <v-card
              outlined
              class="d-flex justify-center align-center"
              height="80px"
              width="80px"
            >
              <v-img
                v-if="member.user.photoUrl.size125x125"
                contain
                :src="member.user.photoUrl.size125x125"
              />
              <v-icon v-else size="40">mdi-account-circle</v-icon>
            </v-card>
          </v-col>
          <v-col cols="9" class="pb-0">
            <v-card flat>
              <v-card-title
                class="text-h6 pt-0 mb-2"
                style="word-break: break-word"
              >
                {{ member.user.displayName }}
              </v-card-title>
              <v-card-subtitle
                v-if="member.user.dutyPosition.title || member.user.rank.title"
                class="pb-0"
              >
                <v-chip
                  outlined
                  label
                  v-if="member.user.rank.title"
                  class="mr-2"
                >
                  <img
                    v-if="member.user.rank.badgeUrl.size125x125"
                    :src="member.user.rank.badgeUrl.size125x125"
                    contain
                    height="20px"
                    class="mr-2"
                  />
                  <span class="font-weight-medium">{{
                    member.user.rank.shortTitle
                  }}</span>
                </v-chip>
                <!-- <img
                  v-if="member.user.rank.badgeUrl.size125x125"
                  contain
                  height="20px"
                  :src="member.user.rank.badgeUrl.size125x125"
                  class="mr-2"
                /> -->
                <!-- style="transform: rotate(-0.25turn)" -->
                <!-- <v-chip v-else" label small outlined>{{
                  member.user.rank.title
                }}</v-chip> -->

                <v-chip outlined label v-if="member.user.dutyPosition.title">
                  <img
                    v-if="member.user.dutyPosition.badgeUrl.size125x125"
                    :src="member.user.dutyPosition.badgeUrl.size125x125"
                    contain
                    height="20px"
                    class="mr-2"
                  />
                  <span class="font-weight-medium">{{
                    member.user.dutyPosition.shortTitle
                  }}</span>
                </v-chip>
                <!-- <img
                  v-if="member.user.dutyPosition.badgeUrl.size125x125"
                  contain
                  height="20px"
                  :src="member.user.dutyPosition.badgeUrl.size125x125"
                /> -->
                <!-- style="transform: rotate(-0.25turn)" -->
                <!-- <v-chip v-else label small outlined>{{
                  member.user.dutyPosition.title
                }}</v-chip> -->
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider v-if="checkSkillsAvailability"></v-divider>

      <v-card-text v-if="checkSkillsAvailability">
        <div class="text-caption font-weight-medium mt-n2 mb-1">
          Qualifikationen
        </div>
        <v-row class="match-height" dense>
          <v-col
            v-if="
              memberManagementConfig.badgeDisplay.primarySkillGroupId &&
              member.relevantSkills.data.length
            "
            cols="3"
          >
            <v-card
              outlined
              class="pa-2 d-flex justify-center align-center"
              height="80px"
              width="80px"
            >
              <v-img contain :src="primaryBadgeUrl"> </v-img>
            </v-card>
          </v-col>
          <v-col
            :cols="
              memberManagementConfig.badgeDisplay.primarySkillGroupId &&
              member.relevantSkills.data.length
                ? '9'
                : '12'
            "
          >
            <div v-if="!member.relevantSkills.data.length" class="mb-2">
              Keine Qualifikationen zugewiesen
            </div>
            <v-card flat v-else class="ml-4">
              <v-chip
                v-for="(skill, index) in member.relevantSkills.data"
                :key="index"
                label
                small
                :color="`${getMaterialColor(skill.group.color)} lighten-5`"
                class="font-weight-medium text--darken-4 mr-2 mb-2"
                :class="getTextStyle(skill.group.color)"
                ><v-icon left small>mdi-{{ skill.group.icon }}</v-icon
                >{{ skill.title }}</v-chip
              >
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- <v-card-text v-if="checkSkillsAvailability" class="mb-n8">
        
      </v-card-text> -->

      <v-divider></v-divider>
      <!-- <v-card-title
        v-if="checkAssignmentsAvailability"
        class="mb-n2 v-card__text"
      >
        <span class="subtitle-1 font-weight-medium mb-1">Aufgabenbereiche</span>
      </v-card-title> -->
      <v-card-text v-if="checkAssignmentsAvailability">
        <div class="text-caption font-weight-medium mt-n2 mb-1">
          Aufgabenbereiche
        </div>
        <div v-if="!member.assignments.ids.length" class="mb-2">
          Keine Aufgabenbereiche zugewiesen
        </div>
        <v-chip
          v-else
          v-for="(assignment, index) in member.assignments.data"
          :key="index"
          small
          label
          :color="`${getMaterialColor(assignment.color)} lighten-5`"
          class="font-weight-medium text--darken-4 mr-2 mb-2"
          :class="getTextStyle(assignment.color)"
          ><v-icon small class="mr-2" :color="assignment.color"
            >mdi-{{ assignment.icon }}</v-icon
          >{{ assignment.title }}</v-chip
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "personnel-record-detail-card",
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAssignments: true,
      showSkills: true,
    };
  },
  computed: {
    checkAssignmentsAvailability() {
      return this.$store.getters["organization/checkExtensionAvailabilityById"](
        "memberManagement.assignments"
      );
    },
    checkSkillsAvailability() {
      return this.$store.getters["organization/checkExtensionAvailabilityById"](
        "memberManagement.skills"
      );
    },
    memberManagementConfig() {
      return this.$store.state.organization.activeOrganization.config
        .memberManagement;
    },
    primaryBadgeUrl() {
      const primarySkillGroupId =
        this.memberManagementConfig.badgeDisplay.primarySkillGroupId || "";
      if (primarySkillGroupId) {
        const skillsOfPrimaryGroup = this.member.relevantSkills.data.filter(
          (skill) => skill.group.id === primarySkillGroupId
        );

        if (skillsOfPrimaryGroup.length === 0) {
          return "";
        }

        const highestPrimarySkill = skillsOfPrimaryGroup.reduce(
          (prev, current) => (prev.sortKey > current.sortKey ? prev : current),
          skillsOfPrimaryGroup[0]
        );

        return highestPrimarySkill.badgeUrl.size200x200;
      } else {
        return "";
      }
    },
  },
  methods: {
    getTextStyle(color) {
      return this.getMaterialColor(color) + "--text";
    },
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
          return "red";
        case "#E91E63FF":
          return "pink";
        case "#9C27B0FF":
          return "purple";
        case "#673AB7FF":
          return "deep-purple";
        case "#3F51B5FF":
          return "indigo";
        case "#2196F3FF":
          return "blue";
        case "#03A9F4FF":
          return "light-blue";
        case "#00BCD4FF":
          return "cyan";
        case "#009688FF":
          return "teal";
        case "#4CAF50FF":
          return "green";
        case "#8BC34AFF":
          return "light-green";
        case "#CDDC39FF":
          return "lime";
        case "#FFEB3BFF":
          return "yellow";
        case "#FFC107FF":
          return "amber";
        case "#FF9800FF":
          return "orange";
        case "#FF5722FF":
          return "deep-orange";
        case "#795548FF":
          return "brown";
        case "#607D8BFF":
          return "blue-grey";
        case "#9E9E9EFF":
          return "grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
