var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"match-height",attrs:{"dense":""}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-card',{staticClass:"d-flex justify-center align-center",attrs:{"outlined":"","height":"80px","width":"80px"}},[(_vm.member.user.photoUrl.size125x125)?_c('v-img',{attrs:{"contain":"","src":_vm.member.user.photoUrl.size125x125}}):_c('v-icon',{attrs:{"size":"40"}},[_vm._v("mdi-account-circle")])],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"9"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-h6 pt-0 mb-2",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.member.user.displayName)+" ")]),(_vm.member.user.dutyPosition.title || _vm.member.user.rank.title)?_c('v-card-subtitle',{staticClass:"pb-0"},[(_vm.member.user.rank.title)?_c('v-chip',{staticClass:"mr-2",attrs:{"outlined":"","label":""}},[(_vm.member.user.rank.badgeUrl.size125x125)?_c('img',{staticClass:"mr-2",attrs:{"src":_vm.member.user.rank.badgeUrl.size125x125,"contain":"","height":"20px"}}):_vm._e(),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.member.user.rank.shortTitle))])]):_vm._e(),(_vm.member.user.dutyPosition.title)?_c('v-chip',{attrs:{"outlined":"","label":""}},[(_vm.member.user.dutyPosition.badgeUrl.size125x125)?_c('img',{staticClass:"mr-2",attrs:{"src":_vm.member.user.dutyPosition.badgeUrl.size125x125,"contain":"","height":"20px"}}):_vm._e(),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.member.user.dutyPosition.shortTitle))])]):_vm._e()],1):_vm._e()],1)],1)],1)],1),(_vm.checkSkillsAvailability)?_c('v-divider'):_vm._e(),(_vm.checkSkillsAvailability)?_c('v-card-text',[_c('div',{staticClass:"text-caption font-weight-medium mt-n2 mb-1"},[_vm._v(" Qualifikationen ")]),_c('v-row',{staticClass:"match-height",attrs:{"dense":""}},[(
            _vm.memberManagementConfig.badgeDisplay.primarySkillGroupId &&
            _vm.member.relevantSkills.data.length
          )?_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"pa-2 d-flex justify-center align-center",attrs:{"outlined":"","height":"80px","width":"80px"}},[_c('v-img',{attrs:{"contain":"","src":_vm.primaryBadgeUrl}})],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.memberManagementConfig.badgeDisplay.primarySkillGroupId &&
            _vm.member.relevantSkills.data.length
              ? '9'
              : '12'}},[(!_vm.member.relevantSkills.data.length)?_c('div',{staticClass:"mb-2"},[_vm._v(" Keine Qualifikationen zugewiesen ")]):_c('v-card',{staticClass:"ml-4",attrs:{"flat":""}},_vm._l((_vm.member.relevantSkills.data),function(skill,index){return _c('v-chip',{key:index,staticClass:"font-weight-medium text--darken-4 mr-2 mb-2",class:_vm.getTextStyle(skill.group.color),attrs:{"label":"","small":"","color":`${_vm.getMaterialColor(skill.group.color)} lighten-5`}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-"+_vm._s(skill.group.icon))]),_vm._v(_vm._s(skill.title))],1)}),1)],1)],1)],1):_vm._e(),_c('v-divider'),(_vm.checkAssignmentsAvailability)?_c('v-card-text',[_c('div',{staticClass:"text-caption font-weight-medium mt-n2 mb-1"},[_vm._v(" Aufgabenbereiche ")]),(!_vm.member.assignments.ids.length)?_c('div',{staticClass:"mb-2"},[_vm._v(" Keine Aufgabenbereiche zugewiesen ")]):_vm._l((_vm.member.assignments.data),function(assignment,index){return _c('v-chip',{key:index,staticClass:"font-weight-medium text--darken-4 mr-2 mb-2",class:_vm.getTextStyle(assignment.color),attrs:{"small":"","label":"","color":`${_vm.getMaterialColor(assignment.color)} lighten-5`}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":assignment.color}},[_vm._v("mdi-"+_vm._s(assignment.icon))]),_vm._v(_vm._s(assignment.title))],1)})],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }